

export default {
	name: "Home",
	layout: "website",
	data() {
		return {
			data: {},
			serverData: {
				cards: [
					// "/images/banners/01.jpg",
					// "/images/banners/02.jpg",
					// "/images/banners/03.jpg",

					"/images/banners/04.jpg",
					"/images/banners/05.jpg",
					"/images/banners/06.jpg",
				],
				mostOrdered: [],
			},
			mostPopular: [],
			newArrival: [],
			offers: [],
			brands: [],
			banners: [],
		}
	},
	async fetch() {
		const promises = [
			this.$axios.$get(`v1/most-popular`).then((resp) => {
				this.mostPopular = resp
			}),
			this.$axios.$get(`v1/new-arrival`).then((resp) => {
				this.newArrival = resp
			}),
			this.$axios.$get(`v1/products/offers?perPage=10`).then((resp) => {
				this.offers = resp.items
			}),
			this.$axios.$get(`v1/lookups-website/brands?inHomePage=1`).then((resp) => {
				this.brands = resp
			}),
			this.$axios.$get(`v1/banners`).then((resp) => {
				this.banners = resp
			}),
			this.$axios.$get(`v1/products/offers?perPage=10`).then((resp) => {
				this.offers = resp.items
			}),
		]

		await Promise.all(promises)
	},
	head() {
		return {
			script: [
				{
					type: "application/ld+json",
					innerHTML: JSON.stringify(this.jsonld), // <- set jsonld object in data or wherever you want
				},
			],
			__dangerouslyDisableSanitizers: ["script"], // <- this is important

			title: this.pageTitle,
			titleTemplate: (titleChunk) => {
				const appName = this.$applicationName()
				return titleChunk ? `${appName} | ${titleChunk}` : appName
			},
			meta: [
				{
					hid: "description",
					name: "description",
					content: this.getDescription,
				},
				{
					hid: "og:title",
					property: "og:title",
					content: this.pageTitle,
				},
				{
					hid: "og:description",
					property: "og:description",
					content: this.getOgDescription,
				},
				{
					hid: "og:image",
					property: "og:image",
					content:
						"/images/logo.png",

				},
				{
					hid: "og:url",
					property: "og:url",
					content: this.$route.fullPath,
				},
				{
					hid: "og:type",
					property: "og:type",
					content: "home",
				},
				{
					hid: "og:site_name",
					property: "og:site_name",
					content: "Action.jo",
				},
				{
					hid: "twitter:title",
					name: "twitter:title",
					content: this.pageTitle,
				},
				{
					hid: "twitter:description",
					name: "twitter:description",
					content: this.getDescription,
				},
				{
					hid: "twitter:image",
					name: "twitter:image",
					content: "/images/logo.png",
				},
				{
					hid: "twitter:card",
					name: "twitter:card",
					content: "summary_large_image",
				},
			],
			link: this.generateLinks,
		}
	},

	computed: {
		categories() {
			return this.$store.state.categories
		},
		getDescription() {
			return this.$i18n.locale === 'ar' ? "تسوق كل ما تحتاجه أونلاين في الأردن عبر أكشن موبايل ✔️ موبايلات، إلكترونيات، أكسسوارات، أجهزة منزلية والمزيد ✔️ أفضل الأسعار والجودة مضمونة!" : "Shop everything you need online in Jordan at Action Mobile ✔️ Electronics, smartphones, accessories, home appliances, and more ✔️ Best prices and top quality guaranteed!"
		},

		titleH1() {
			return this.$i18n.locale === 'ar' ? "اكشن موبايل" : "Action Mobile"
		},

		getOgDescription() {
			return this.$i18n.locale === 'ar' ? "تسوق كل ما تحتاجه أونلاين في الأردن عبر أكشن موبايل ✔️ موبايلات، إلكترونيات، أكسسوارات، أجهزة منزلية والمزيد ✔️ أفضل الأسعار والجودة مضمونة!" : "Shop everything you need online in Jordan at Action Mobile ✔️ Electronics, smartphones, accessories, home appliances, and more ✔️ Best prices and top quality guaranteed!"
		},
		pageTitle() {
			return this.$i18n.locale === 'ar' ? ' تسوق كل ما تحتاجه أونلاين في الأردن' : 'Shop Everything You Need Online in Jordan'
		},

		generateLinks() {
			if (this.$fetchState.pending) { return [] }

			const links = [
				{
					rel: "canonical",
					href: `${process.env.NUXT_ENV_BASE_URL}/${this.$i18n.locale}`,
				},
				{
					rel: "alternate",
					hreflang: "en",
					href: `${process.env.NUXT_ENV_BASE_URL}/${this.$i18n.locale}`,
				},
			]
			return links
		},
	},
}
